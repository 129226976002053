var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.amounts
    ? _c(
        "ul",
        { staticClass: "wpcm-pre-dfind-amt" },
        _vm._l(_vm.amounts, function(amnt) {
          return _c(
            "li",
            {
              key: amnt,
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.setValue("amount", _vm.convert(amnt))
                }
              }
            },
            [
              _c(
                "a",
                {
                  class: [
                    "wpdonation-button",
                    _vm.amount == _vm.convert(amnt) && "active"
                  ],
                  attrs: { href: "#" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.getSymbol()) +
                      _vm._s(_vm.convert(amnt))
                  )
                ]
              )
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }