import Schema from 'async-validator';
const Vuex = window.Vuex;
const $ = window.jQuery;


const state_object = {
  config: {},
  components: [],
  showModal: false,
  post_id: 0,
  style: 1,
  loading: false,
  amount: 0,
  billing_fields: { email: '' },
  token: "",
  currency: "",
  recurring: false,
  payment_method: "",
  cycle: "",
  step: 1,
  cc: {},
  extras: {},
  proceed_submit: false
};

const actions = {
  getData({ commit, state }, data) {
    const { vm } = data;

    if (vm.id) {
      state.post_id = vm.id
    }
    if (vm.dstyle) {
      state.style = vm.dstyle
    }
    state.loading = true;
    $.ajax({
      url: lifeline_donation.ajaxurl,
      type: "post",
      data: {
        action: "lifeline_donation_data",
        id: state.post_id,
        style: state.style,
        nonce: lifeline_donation.nonce
      },
      success: res => {
        state.loading = false;
        state.components = res.data.components;
        state.config = res.data.config;
        state.showModal = true
      },
      complete: res => {
        state.loading = false
        if (res.status !== 200) {
          vm.$notify.error({
            title: res.statusText,
            message: res.responseText,
            dangerouslyUseHTMLString: true,
            offset: 40
          })
        }
      }
    });
  },
  submit({ commit, state }, data) {
    const { eventBus } = lifeline_donation
    const { payment_method, proceed_submit } = state
    eventBus.$emit('lifeline-donation-form-submit', state);

    if( payment_method === 'offline' || payment_method === 'paypal' ) {
      commit('setValue', {key: 'proceed_submit', val: true})
    }
    if (! state.proceed_submit) {
      return
    }
    state.loading = true
    const { vm } = data
    $.ajax({
      url: lifeline_donation.ajaxurl,
      type: "post",
      data: {
        action: "lifeline_donation_donate_now",
        post_id: state.post_id,
        amount: state.amount,
        currency: state.currency,
        gateway: state.payment_method,
        recurring: state.recurring,
        type: (!state.post_id) ? 'general' : 'single',
        info: state.billing_fields,
        nonce: lifeline_donation.nonce,
        cycle: state.cycle,
        extras: state.extras,
        cc: state.cc
      },
      success: res => {
        if (res.type == 'redirect') {
          window.location = res.url
        }
        eventBus.$emit('webinane_commerce_checkout_form_submitted', res);
        // $(document).trigger('webinane_commerce_checkout_form_submitted', res)
      },
      complete: res => {
        if (res.status !== 200) {
          state.loading = false
          console.log(res)
          const json = res.responseJSON;
          let messages = _.get(json, 'data.messages')
          messages = (!messages) ? _.get(json, ['data', 'message']) : messages
          messages = (!messages) ? res.responseText : messages

          vm.$notify.error({
            title: res.statusText,
            message: messages,
            dangerouslyUseHTMLString: true,
            offset: 40
          })
        }
      }
    });
  },
  validate({ commit, state }, data) {
    const { fields, rules } = data

    return new Promise((resolve, reject) => {
      const validator = new Schema(rules);
      validator.validate(fields, (errors, fields) => {
        if (errors) {
          reject(fields, errors)
        } else {
          resolve()
        }
      })
    })
  }
};

const mutations = {
  loading(state, loading) {
    state.loading = loading
  },
  setAmount(state, amount) {
    state.amount = parseFloat(amount.target.value);
  },
  setValue(state, data) {
    const { key, val } = data;
    if (key === "amount") {
      state[key] = parseFloat(val);
    } else {
      state[key] = val;
    }
  },
  next(state) {
    state.step = state.step + 1;
  },
  back(state) {
    state.step = state.step - 1;
  },
  setBillingValue(state, data) {
    const { key, val } = data;
    state.billing_fields[key] = val;
  },
  setExtras(state, data) {
    const { key, val } = data;
    state.extras[key] = val;
  },
  reset(state) {
    // console.log(state_object)
    _.each(state_object, (value, key) => {
      state[key] = value
    })
    console.log(state)
  }
};

export default new Vuex.Store({
  state: { ...state_object },
  actions,
  mutations
});
