<template>
  <ul class="wpcm-pre-dfind-amt" v-if="amounts">
    <li
      v-for="(amnt) in amounts"
      :key="amnt"
      @click.prevent="setValue('amount', convert(amnt))"
    >
      <a
        href="#"
        :class="[
          'wpdonation-button',
          amount == convert(amnt) && 'active'
        ]"
      >
        {{ getSymbol() }}{{ convert(amnt) }}</a
      >
    </li>
  </ul>
</template>

<script>
const { mapState, mapMutations } = window.Vuex;
export default {
  props: ['amounts', 'symbols', 'symbol'],
  computed: {
    ...mapState(["amount", "currency", "recurring"]),
  },
  mounted() {
    if(! this.amount && _.size(this.amounts)) {
      this.setValue('amount', this.amounts[0])
    }
  },
  methods: {
    ...mapMutations(["setAmount"]),
    setValue(key, val) {
      this.$store.commit("setValue", { key, val });
    },
    getSymbol() {
      return (this.symbols[this.currency] != undefined) ? this.symbols[this.currency] : this.symbol
    },
    convert(amt) {
      const { exchange_rates } = window.lifeline_donation
      const syb = this.getSymbol()
      console.log(_.get(exchange_rates, syb))
      if(_.get(exchange_rates, syb)) {
        return parseFloat(_.get(exchange_rates, syb) * amt);
      }

      return amt
    }
  }
}
</script>