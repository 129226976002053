var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wpcm-amount-box" }, [
    _c(
      "div",
      { staticClass: "wpcm-donation-amt-fields" },
      [
        _vm.show_currency_dropdown && _vm.currencies
          ? _c(
              "div",
              { staticClass: "el-custom-select" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "w-100",
                    attrs: { value: _vm.currency },
                    on: {
                      change: function($event) {
                        return _vm.setValue("currency", $event)
                      }
                    }
                  },
                  _vm._l(_vm.currencies, function(curr, key) {
                    return _c(
                      "el-option",
                      { key: key, attrs: { value: key, label: curr } },
                      [_vm._v(_vm._s(curr))]
                    )
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._t("donation_dropdowns"),
        _vm._v(" "),
        _vm.strings && _vm.show_amounts && _vm.amounts
          ? _c("div", {}, [
              _c("strong", { staticClass: "wpcm-cstm-amt-txt" }, [
                _vm._v(_vm._s(_vm.strings.how_much))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.show_amounts && _vm.amounts
          ? _c(
              "ul",
              { staticClass: "wpcm-pre-dfind-amt" },
              _vm._l(_vm.amounts, function(amnt) {
                return _c(
                  "li",
                  {
                    key: amnt,
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.setValue("amount", _vm.convert(amnt))
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        class: [
                          "wpdonation-button",
                          _vm.amount == _vm.convert(amnt) && "active"
                        ],
                        attrs: { href: "#" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getSymbol()) +
                            _vm._s(_vm.convert(amnt))
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }