import { render, staticRenderFns } from "./ProceedBtn.vue?vue&type=template&id=3eb5d59a&"
import script from "./ProceedBtn.vue?vue&type=script&lang=js&"
export * from "./ProceedBtn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\laragon\\www\\webinane\\wp-content\\plugins\\lifeline-donation-pro\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3eb5d59a')) {
      api.createRecord('3eb5d59a', component.options)
    } else {
      api.reload('3eb5d59a', component.options)
    }
    module.hot.accept("./ProceedBtn.vue?vue&type=template&id=3eb5d59a&", function () {
      api.rerender('3eb5d59a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/ProceedBtn.vue"
export default component.exports